body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

img {
  width: 25%;
  height: 50%;
  transition: transform 0.3s ease-in-out;
}

.headerContainer {
  text-align: center;
  margin-bottom: -450px;
}

.sparkle-container {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  position: relative;
}

.sparkles {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.sparkle-container:hover .sparkles {
  display: block;
}

.sparkle-container:hover .sparkle-image {
  transform: scale(1.05);
}

.sparkle {
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: yellow;
  border-radius: 50%;
  opacity: 0;
  animation: sparkle 1s infinite;
}

@keyframes sparkle {
  0%, 100% {
      opacity: 0;
      transform: scale(0);
  }
  50% {
      opacity: 1;
      transform: scale(1);
  }
}

.sparkle:nth-child(1) {
  top: 20%;
  left: 30%;
  animation-delay: 0s;
}

.sparkle:nth-child(2) {
  top: 50%;
  left: 50%;
  animation-delay: 0.2s;
}

.sparkle:nth-child(3) {
  top: 70%;
  left: 20%;
  animation-delay: 0.4s;
}

.sparkle:nth-child(4) {
  top: 30%;
  left: 70%;
  animation-delay: 0.6s;
}

.sparkle:nth-child(5) {
  top: 80%;
  left: 80%;
  animation-delay: 0.8s;
}

.sparkle:nth-child(6) {
  top: 40%;
  left: 40%;
  animation-delay: 1s;
}

.sparkle:nth-child(7) {
  top: 60%;
  left: 60%;
  animation-delay: 1.2s;
}

.sparkle:nth-child(8) {
  top: 20%;
  left: 60%;
  animation-delay: 1.4s;
}

.sparkle:nth-child(9) {
  top: 80%;
  left: 40%;
  animation-delay: 1.6s;
}

.sparkle:nth-child(10) {
  top: 50%;
  left: 20%;
  animation-delay: 1.8s;
}

.sparkle:nth-child(11) {
  top: 50%;
  left: 50%;
  animation-delay: 1s;
}

.sparkle:nth-child(12) {
  top: 75%;
  left: 60%;
  animation-delay: .2s;
}

.sparkle:nth-child(13) {
  top: 30%;
  left: 50%;
  animation-delay: 2.4s;
}

.sparkle:nth-child(14) {
  top: 15%;
  left: 20%;
  animation-delay: .6s;
}

.sparkle:nth-child(15) {
  top: 5%;
  left: 80%;
  animation-delay: .8s;
}

.sparkle:nth-child(16) {
  top: 90%;
  left: 20%;
  animation-delay: 1.1s;
}

.sparkle:nth-child(17) {
  top: 59%;
  left: 69%;
  animation-delay: 1.7s;
}

.sparkle:nth-child(18) {
  top: 45%;
  left: 60%;
  animation-delay: .4s;
}

.sparkle:nth-child(19) {
  top: 8%;
  left: 20%;
  animation-delay: 1.1s;
}

.sparkle:nth-child(20) {
  top: 45%;
  left: 20%;
  animation-delay: 2s;
}